import merge from 'lodash/merge';
import { DateTime, Duration } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { twMerge } from 'tailwind-merge';

import { colors } from '@packages/design';
import { getRegionSelector, regionDateUtils, useRegionStore } from '@packages/stores';
import { CalendarData, MarkedDates, RegionCode } from '@packages/types';
import {
  getDateDifferenceInDays,
  getDurationOfDatesInDays,
  getHighlightedDates,
  getJSDate,
  getRegionDateTime,
  getRestrictedMarkDates,
  getUTCDate,
  getUTCDateFromJSDate,
  parseISO,
} from '@packages/utils';

import { Assets } from '@assets';
import { testProps } from '@utils';

export interface CalendarProps {
  onSelectDuration: (startDate: string, endDate: string) => void;
  currentDate?: string; // YYYY-MM-DD - initial visible date
  startDate?: string; // YYYY-MM-DD
  endDate?: string; // YYYY-MM-DD
  editMode?: boolean;
  minDate?: string | DateTime;
  maxDate?: string | DateTime;
  country: RegionCode;
  onValidate?: (data: CalendarData, startDate: string, endDate: string) => boolean;
  onValidateStartDate?: (dateString: string) => boolean;
  showDefaultDuration?: boolean;
  numberOfProtectionRestrictionDays?: number;
  highlightedDateRange?: { startDate: string; endDate: string };
  onRestrictionDayPressed?: () => void;
  mode?: 'DATE_RANGE' | 'DAY';
  multiDateRange?: boolean;
  selectedRange?: { startDate: string; endDate: string }[];
  onSelectedRangeChange?: (selectedRange: { startDate: string; endDate: string }[]) => void | null;
}

export const Calendar: React.FC<CalendarProps> = ({
  onSelectDuration,
  editMode,
  currentDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  country,
  onValidate,
  showDefaultDuration,
  numberOfProtectionRestrictionDays = 0,
  highlightedDateRange,
  onValidateStartDate,
  onRestrictionDayPressed,
  mode = 'DATE_RANGE',
  selectedRange,
  multiDateRange = false,
  onSelectedRangeChange,
}) => {
  const selectedColor = 'bg-nusa-200';
  const highlightedColor = 'bg-nusa-50';

  const restrictedColor = 'bg-red-50';
  const restrictedTextColor = 'text-red-500';

  const region = useRegionStore(getRegionSelector);
  const today = getRegionDateTime(region?.country);
  const restrictiveDays = useMemo(
    () =>
      getRestrictedMarkDates(
        numberOfProtectionRestrictionDays,
        country,
        restrictedColor,
        restrictedTextColor,
      ),
    [country, numberOfProtectionRestrictionDays],
  );

  const [dateRanges, setDateRanges] = useState<{ startDate: string; endDate: string }[]>(
    selectedRange ?? [],
  );

  const [hasUpdatedMarkedDates, setHasUpdatedMarkedDates] = useState(false);

  const [trackedStartDate, setTrackedStartDate] = useState<{ startDate: string }[]>([]);

  const [selectedStates, setSelectedStates] = useState<boolean[]>(
    new Array(dateRanges.length).fill(false),
  );
  const pickSingleDate = useMemo(() => mode === 'DAY', [mode]);

  const highlightedDates = useMemo(() => {
    if (pickSingleDate && startDate) {
      return getHighlightedDates(startDate, startDate, region?.country, selectedColor);
    }
    if (highlightedDateRange?.startDate && highlightedDateRange.endDate) {
      return getHighlightedDates(
        highlightedDateRange.startDate,
        highlightedDateRange.endDate,
        region?.country,
        highlightedColor,
      );
    }
    return {};
  }, [
    highlightedDateRange?.endDate,
    highlightedDateRange?.startDate,
    region?.country,
    pickSingleDate,
    startDate,
  ]);

  const [duration, setDuration] = useState({
    markedDates: { ...restrictiveDays, ...highlightedDates } as MarkedDates,
    startDate: startDate || '',
    endDate: endDate || '',
    durationClosed: pickSingleDate,
  });

  function isDateInRange(dateRanges: { startDate: string; endDate: string }[], date: string) {
    const index = dateRanges.findIndex(range => {
      const startDate = parseISO(range.startDate, region?.country);
      const endDate = parseISO(range?.endDate ?? range?.startDate, region?.country);
      const selectedDate = parseISO(date, region?.country);
      return selectedDate >= startDate && selectedDate <= endDate;
    });

    return index;
  }

  function deselectRange(index: number) {
    const updatedRanges = [...dateRanges];
    updatedRanges.splice(index, 1);
    setDateRanges(updatedRanges);

    const updatedSelectedStates = [...selectedStates];
    updatedSelectedStates.splice(index, 1);
    setSelectedStates(updatedSelectedStates);

    // Update the marked dates to reflect the deselected state
    const markedDates: MarkedDates = {};
    updatedRanges.forEach(range => {
      merge(
        markedDates,
        getHighlightedDates(range.startDate, range.endDate, region?.country, selectedColor),
      );
    });
    setDuration(prevState => ({
      ...prevState,
      markedDates: { ...restrictiveDays, ...highlightedDates, ...markedDates },
    }));
    onSelectedRangeChange?.(updatedRanges);
  }

  const isRangeOverlapping = (
    currentDateRanges: { startDate: string; endDate: string }[],
    newRange: { startDate: string; endDate: string },
  ) => {
    const newRangeStartDate = parseISO(newRange.startDate, region?.country);
    const newRangeEndDate = parseISO(newRange.endDate, region?.country);
    const newRangeDuration = newRangeEndDate.diff(newRangeStartDate, 'days').days;
    const newRangeDates: string[] = [];
    for (let i = 0; i <= newRangeDuration; i++) {
      newRangeDates.push(newRangeStartDate.plus({ days: i }).toISODate() as string);
    }
    const newRangeOverlaps = currentDateRanges.filter(range => {
      const rangeStartDate = parseISO(range.startDate, region?.country);
      const rangeEndDate = parseISO(range.endDate, region?.country);
      const rangeDuration = rangeEndDate.diff(rangeStartDate, 'days').days;
      const rangeDates: string[] = [];
      for (let i = 0; i <= rangeDuration; i++) {
        rangeDates.push(rangeStartDate.plus({ days: i }).toISODate() as string);
      }
      return newRangeDates.some(date => rangeDates.includes(date));
    });

    return newRangeOverlaps;
  };

  const getRangeOverlapIndex = (newRangeOverlaps: { startDate: string; endDate: string }[]) => {
    const newRangeOverlapsStartDate = parseISO(newRangeOverlaps[0].startDate, region?.country);
    const newRangeOverlapsEndDate = parseISO(newRangeOverlaps[0].endDate, region?.country);
    const newRangeOverlapsDuration = newRangeOverlapsEndDate.diff(
      newRangeOverlapsStartDate,
      'days',
    ).days;
    const newRangeOverlapsDates: string[] = [];
    for (let i = 0; i <= newRangeOverlapsDuration; i++) {
      newRangeOverlapsDates.push(newRangeOverlapsStartDate.plus({ days: i }).toISODate() as string);
    }
    const newRangeOverlapsIndex = dateRanges.findIndex(range =>
      newRangeOverlapsDates.includes(range.startDate),
    );
    return newRangeOverlapsIndex;
  };

  const mergeMarkedDates = (markedDates: MarkedDates, newStartDate: string, newEndDate: string) => {
    let dateToIncrement = parseISO(newStartDate, region?.country);
    const endDateParseIso = parseISO(newEndDate, region?.country);
    const oneDayDuration = Duration.fromObject({ days: 1 });

    while (dateToIncrement < endDateParseIso) {
      merge(markedDates, {
        [getUTCDate(dateToIncrement)]: { color: selectedColor },
      });
      dateToIncrement = dateToIncrement.plus(oneDayDuration);
    }

    merge(markedDates, {
      [getUTCDate(dateToIncrement)]: {
        endingDay: true,
        color: selectedColor,
      },
    });
    return markedDates;
  };

  /*
  This function addresses the scenario where the user selects two start dates sequentially.
  When an initial start date is chosen, and then a second start date prior to the initial one is selected,
  the code interprets the second start date as a new start date instead of an end date, affecting marked dates.
  The code below adjusts the marked dates to represent the selected state of the new start date
  as well as the dates between the new start date and the original start date.
  It further updates the date ranges and selected states to incorporate the new start date and the intervening dates.
*/

  const twoStartDates = (newTrackDates: { startDate: string }[]) => {
    const newStartDate = newTrackDates[1].startDate;
    const newEndDate = newTrackDates[0].startDate;
    const newRange = {
      startDate: newStartDate,
      endDate: newEndDate,
    };

    const newDateRanges = [...dateRanges, newRange];
    const newSelectedStates = [...selectedStates, true];

    setSelectedStates(newSelectedStates);
    setDateRanges(newDateRanges);

    const markedDates: MarkedDates = {};
    markedDates[newEndDate] = {
      startingDay: false,
      color: selectedColor,
      endingDay: true,
    };
    markedDates[newStartDate] = {
      startingDay: true,
      color: selectedColor,
    };
    mergeMarkedDates(markedDates, newStartDate, newEndDate);

    // Check if the new range overlaps with any of the existing ranges and remove all overlapping ranges
    const newRangeOverlaps = isRangeOverlapping(dateRanges, newRange);
    if (newRangeOverlaps.length > 0) {
      const newRangeOverlapsIndexes = newRangeOverlaps.map(overlapRange =>
        getRangeOverlapIndex([overlapRange]),
      );

      // Sort the indexes in descending order so that we can remove elements without affecting the index of subsequent elements
      newRangeOverlapsIndexes.sort((a, b) => b - a);

      for (const indexToRemove of newRangeOverlapsIndexes) {
        newDateRanges.splice(indexToRemove, 1);
        newSelectedStates.splice(indexToRemove, 1);
      }
    }
    // Update duration and trigger selection
    setDuration(prevState => ({
      ...prevState,
      markedDates: Object.assign(
        {},
        prevState.markedDates,
        getHighlightedDates(newStartDate, newEndDate, region?.country, selectedColor),
      ),
      startDate: '',
      endDate: '',
      durationClosed: false,
    }));

    onSelectedRangeChange?.([...dateRanges, newRange]);
    setTrackedStartDate([]);
  };

  const selectStartDateMultiDateRange = (dateString: string) => {
    // Check if the pressed date belongs to an already selected range
    const index = isDateInRange(dateRanges, dateString);

    if (index !== -1) {
      deselectRange(index);
      return;
    }

    const newTrackDates = [...trackedStartDate, { startDate: dateString }];
    setTrackedStartDate(newTrackDates);

    const newMarkedDates: MarkedDates = {
      [dateString]: {
        startingDay: true,
        color: selectedColor,
      },
    };

    if (newTrackDates.length === 2) {
      twoStartDates(newTrackDates);
    } else {
      setDuration(prevState => ({
        ...prevState,
        markedDates: Object.assign({}, prevState.markedDates, newMarkedDates),
        startDate: dateString,
        endDate: dateString,
        durationClosed: false,
      }));
      onSelectedRangeChange?.([...dateRanges, { startDate: dateString, endDate: dateString }]);
    }
  };

  const selectEndDateMultiDateRange = (dateString: string) => {
    const newDateRange = { startDate: duration.startDate, endDate: dateString };
    const updatedDateRanges = [...dateRanges, newDateRange];
    const updatedSelectedStates = [...selectedStates, true];
    setTrackedStartDate([]);

    // Check if the new range overlaps with any of the existing ranges and remove all overlapping ranges
    const newRangeOverlaps = isRangeOverlapping(dateRanges, newDateRange);
    if (newRangeOverlaps.length > 0) {
      const newRangeOverlapsIndexes = newRangeOverlaps.map(overlapRange =>
        getRangeOverlapIndex([overlapRange]),
      );

      // Sort the indexes in descending order so that we can remove elements without affecting the index of subsequent elements
      newRangeOverlapsIndexes.sort((a, b) => b - a);

      for (const indexToRemove of newRangeOverlapsIndexes) {
        updatedDateRanges.splice(indexToRemove, 1);
        updatedSelectedStates.splice(indexToRemove, 1);
        deselectRange(indexToRemove);
      }
    }

    // Update duration and trigger selection
    setDuration(prevState => ({
      ...prevState,
      markedDates: Object.assign(
        {},
        prevState.markedDates,
        getHighlightedDates(
          newDateRange.startDate,
          newDateRange.endDate,
          region?.country,
          selectedColor,
        ),
      ),
      startDate: '',
      endDate: '',
      durationClosed: false,
    }));

    setDateRanges(updatedDateRanges);
    onSelectedRangeChange?.(updatedDateRanges);
    setSelectedStates(updatedSelectedStates);
  };

  const selectStartDate = (dateString: string) => {
    if (onValidateStartDate && onValidateStartDate(dateString) === false) {
      return;
    }

    const markedDates = pickSingleDate ? {} : ({ ...highlightedDates } as MarkedDates);
    markedDates[dateString] = {
      startingDay: true,
      color: selectedColor,
      endingDay: pickSingleDate,
    };

    const startDateStr = dateString;
    const endDateStr = dateString;

    if (multiDateRange) {
      selectStartDateMultiDateRange(dateString);
    } else {
      setDuration({
        markedDates: { ...restrictiveDays, ...markedDates },
        startDate: dateString,
        endDate: dateString,
        durationClosed: pickSingleDate,
      });
      onSelectDuration(startDateStr, endDateStr);
    }
  };

  const selectEndDate = (dateString: string) => {
    const dateToIncrement = parseISO(duration.startDate, region?.country);

    const markedDates = {
      [getUTCDate(dateToIncrement)]: {
        startingDay: true,
        color: selectedColor,
      },
    } as MarkedDates;
    mergeMarkedDates(markedDates, duration.startDate, dateString);

    if (multiDateRange) {
      selectEndDateMultiDateRange(dateString);
    } else {
      setDuration(prevState => ({
        markedDates: { ...restrictiveDays, ...highlightedDates, ...markedDates },
        startDate: prevState.startDate,
        endDate: dateString,
        durationClosed: true,
      }));
      onSelectDuration(duration.startDate, dateString);
    }
  };

  const handleDayPress = (date: Date) => {
    const dateString = getUTCDateFromJSDate(date, country);

    if (
      onRestrictionDayPressed &&
      Object.keys(restrictiveDays).includes(dateString) &&
      numberOfProtectionRestrictionDays > 0
    ) {
      onRestrictionDayPressed();
      return;
    }

    // Shared logic for date selection, duration handling, and validation
    if (
      duration.durationClosed &&
      !(editMode && startDate && regionDateUtils().isInPast(startDate))
    ) {
      selectStartDate(dateString);
      return;
    }

    if (pickSingleDate) {
      selectStartDate(dateString);
      return;
    }

    if (!duration.startDate) {
      selectStartDate(dateString);
    } else {
      const range = getDateDifferenceInDays(duration.startDate, dateString, region?.country);
      const durationInDays = getDurationOfDatesInDays(
        duration.startDate,
        dateString,
        region?.country,
      );

      if (range < 0) {
        selectStartDate(dateString);
        return;
      }

      if (
        typeof onValidate === 'function' &&
        onValidate({ durationInDays }, duration.startDate, duration.endDate) === false
      ) {
        return;
      }

      if (range >= 0) {
        selectEndDate(dateString);
      }
    }
  };

  // Update markedDates to treat start dates without an end date as single dates. This will only run once.
  const updateMarkedSingleStartDates = (markedDates: MarkedDates) => {
    const dateKeys = Object.keys(markedDates);
    for (let i = 0; i < dateKeys.length; i++) {
      const currentDateKey = dateKeys[i];
      const currentDateInfo = markedDates[currentDateKey];
      const nextDate = DateTime.fromISO(currentDateKey).plus({ days: 1 }).toISODate() as string; // Calculate the next date
      const prevDateKey = dateKeys[i - 1];

      const nextDateInfo = markedDates[nextDate];
      const prevDateInfo = markedDates[prevDateKey];

      // if there is only one date in the marked dates, treat it as a single date range
      if (
        dateKeys.length === 1 &&
        currentDateInfo.startingDay &&
        currentDateInfo.color === selectedColor
      ) {
        markedDates[currentDateKey] = {
          ...currentDateInfo,
          endingDay: true,
        };
      }

      // Check if the start date of a range is followed by a single date, make it a single date
      if (
        currentDateInfo.startingDay &&
        !currentDateInfo.endingDay &&
        nextDateInfo?.color === highlightedColor &&
        currentDateInfo.color === selectedColor
      ) {
        markedDates[currentDateKey] = {
          ...currentDateInfo,
          endingDay: true,
        };
      }

      // Check if the end date of a range is followed by a single date, make it a single date
      if (
        !currentDateInfo.endingDay &&
        currentDateInfo.startingDay &&
        prevDateInfo?.color === highlightedColor &&
        i === dateKeys.length - 1
      ) {
        markedDates[currentDateKey] = {
          ...currentDateInfo,
          endingDay: true,
        };
      }
    }
  };

  // Update marked dates and duration for multi-date range
  useEffect(() => {
    if (!multiDateRange || !startDate || !endDate || hasUpdatedMarkedDates) {
      return;
    }

    const markedDates = { ...restrictiveDays, ...highlightedDates } as MarkedDates;
    const ranges = dateRanges.map(range => {
      const rangeStartDate = parseISO(range.startDate, region?.country);
      const rangeEndDate = parseISO(range.endDate, region?.country);
      const daysInRange = rangeEndDate.diff(rangeStartDate, 'days').days;
      return { startDate: rangeStartDate, daysInRange };
    });

    for (const range of ranges) {
      const { startDate, daysInRange } = range;
      markedDates[getUTCDate(startDate)] = { startingDay: true, color: selectedColor };

      for (let i = 1; i <= daysInRange; i++) {
        const date = startDate.plus({ days: i });
        const dayToIncrement = getUTCDate(date);
        markedDates[dayToIncrement] =
          i < daysInRange ? { color: selectedColor } : { endingDay: true, color: selectedColor };
      }
    }

    if (hasUpdatedMarkedDates === false) {
      updateMarkedSingleStartDates(markedDates);
      setHasUpdatedMarkedDates(true);
    }

    setDuration({
      markedDates,
      startDate,
      endDate,
      durationClosed: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update marked dates and duration for single-date range
  useEffect(() => {
    if (multiDateRange) {
      return;
    }
    if ((editMode || showDefaultDuration) && startDate && endDate && !pickSingleDate) {
      const markedDates = {} as MarkedDates;

      markedDates[startDate] = { startingDay: true, color: selectedColor };
      const range = getDateDifferenceInDays(startDate, endDate, region?.country);

      for (let i = 1; i <= range; i++) {
        const dayDuration = Duration.fromObject({ days: i });
        const date = parseISO(startDate, region?.country).plus(dayDuration);
        const dayToIncrement = getUTCDate(date);
        if (i < range) {
          markedDates[dayToIncrement] = { color: selectedColor };
        } else {
          markedDates[dayToIncrement] = { endingDay: true, color: selectedColor };
        }
      }

      setDuration({
        markedDates: { ...restrictiveDays, ...highlightedDates, ...markedDates },
        startDate,
        endDate,
        durationClosed: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let defaultActiveStartDate = getJSDate(getRegionDateTime(region?.country), region?.country);
  if (minDate) {
    defaultActiveStartDate = getJSDate(minDate, region?.country);
  }

  if (startDate) {
    defaultActiveStartDate = getJSDate(startDate, region?.country);
  }

  return (
    <ReactCalendar
      {...testProps('calendar-component')}
      calendarType="US" // first day of the week to Saturday
      className="w-full"
      defaultActiveStartDate={
        currentDate ? getJSDate(currentDate, region?.country) : defaultActiveStartDate
      }
      minDate={minDate ? getJSDate(minDate, region?.country) : undefined}
      maxDate={maxDate ? getJSDate(maxDate, region?.country) : undefined}
      showNeighboringMonth={false}
      onClickDay={handleDayPress}
      tileClassName={({ date }) => {
        const markedDate = duration.markedDates[getUTCDateFromJSDate(date, region?.country)];
        if (markedDate) {
          return twMerge(
            'my-[0.125rem]',
            markedDate.color,
            markedDate.textColor,
            markedDate.startingDay && 'rounded-l-full',
            markedDate.endingDay && 'rounded-r-full',
          );
        } else if (getUTCDateFromJSDate(date, region?.country) === getUTCDate(today)) {
          return twMerge('my-[0.125rem]', 'bg-gray-200', 'rounded-full');
        } else return 'my-[0.125rem]';
      }}
      prevLabel={<Assets.ArrowLeft fill={colors.fuji[800]} {...testProps('btn-calendar-prev')} />}
      nextLabel={<Assets.ArrowRight fill={colors.fuji[800]} {...testProps('btn-calendar-next')} />}
      prev2Label={null}
      next2Label={null}
    />
  );
};
