import { Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { DEFAULT_MAX_CALENDAR_DAYS } from '@packages/constants';
import {
  getRegionSelector,
  maxTripCalendarDaysSelector,
  minDateOffsetDaysSelector,
  protectionRestrictionDaysSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { FormOnChange, TripDatesForm } from '@packages/types';
import { getRegionDateTime, validateTravelDatesSubmitButton } from '@packages/utils';

import { Badge } from '@elements/badge';
import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import {
  openModalSelector,
  tripEndDateSelector,
  tripStartDateSelector,
  useTripStore,
} from '@store';
import { useModalStore } from '@store/modal';
import { sendAnalyticsEvent, testProps } from '@utils';

import { Calendar } from '../calendar';
import { Container } from '../container';
import { FooterButtonGroup } from '../footerButtonGroup';
import { GetQuoteProvider } from '../getQuoteProvider';
import { DateSelected } from './dateSelected.component';
import { DaysOfTravel } from './daysOfTravel.component';

export type SelectTravelDatesProps = {
  onBack: () => void;
  onNext: () => void;

  isModal?: boolean;
};

export const SelectTravelDates = (props: SelectTravelDatesProps) => {
  const startDate = useTripStore(tripStartDateSelector);
  const endDate = useTripStore(tripEndDateSelector);
  const tripDates = { startDate, endDate };

  return (
    <GetQuoteProvider defaultFormValues={{ tripDates }} mode="all" reValidateMode="onChange">
      <SelectTravelDatesContent {...props} />
    </GetQuoteProvider>
  );
};

export const SelectTravelDatesContent = ({ onBack, onNext, isModal }: SelectTravelDatesProps) => {
  // global state
  const openModal = useModalStore(openModalSelector);
  const region = useRegionStore(getRegionSelector);
  const protectionRestrictionDays = useConfigStore(protectionRestrictionDaysSelector);
  const validateTripStartDate = useTripStore(state => state.validateTripStartDate);
  const minDateOffsetDays = useConfigStore(minDateOffsetDaysSelector);
  const maxTripCalendarDays = useConfigStore(maxTripCalendarDaysSelector);
  const validateTripDuration = useTripStore(state => state.validateTripDuration);
  const tripStartDate = useTripStore(tripStartDateSelector);
  const tripEndDate = useTripStore(tripEndDateSelector);
  const syncTrip = useTripStore(state => state.syncTrip);
  const { openPriceChangeModal } = usePriceChangeModal();

  const setTripStartDate = useTripStore(state => state.setStartDate);
  const setTripEndDate = useTripStore(state => state.setEndDate);

  const currentDate = getRegionDateTime(region?.country);

  const [isLoading, setLoading] = useState(false);

  // form state
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<TripDatesForm>();

  const { startDate, endDate } = watch('tripDates');

  useEffect(() => {
    if (isModal) {
      sendAnalyticsEvent('Modal viewed', { modalTitle: i18n.t('travelDates.title') });
    }
  }, [isModal]);

  const handleSelectDateDuration =
    (onFormChange: FormOnChange) => (startDate: string, endDate: string) => {
      onFormChange({ startDate, endDate });
    };

  const handleTripUpdate = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    setTripStartDate(startDate);
    setTripEndDate(endDate);
  };

  const onSubmit = async (data: TripDatesForm) => {
    const { startDate, endDate } = data.tripDates;

    if (isModal) {
      return openPriceChangeModal({
        onNext: async () => {
          setLoading(true);
          await handleTripUpdate({ startDate, endDate });
          onNext();
          await syncTrip();
          setLoading(false);
        },
      });
    } else {
      sendAnalyticsEvent('Trip Dates Added', {
        startDate,
        endDate,
      });
      setLoading(true);
      await handleTripUpdate({ startDate, endDate });
      onNext();
      setLoading(false);
    }
  };

  const openRestrictionDayWarningModal = () => {
    openModal('Modal', {
      titleText: i18n.t('travelDates.restrictionModal.title'),
      titleClassName: 'text-left',
      size: 'md',
      body: {
        type: 'UsTravelDatesProtectionModal',
      },
      actions: [
        {
          title: i18n.t('travelDates.restrictionModal.actions.ok'),
          variant: 'primary',
          className: 'w-full',
        },
      ],
    });
  };

  const maxTripCalendarDaysDuration = Duration.fromObject({
    days: maxTripCalendarDays ?? DEFAULT_MAX_CALENDAR_DAYS,
  });

  // this is inclusive of the start date
  const twoDayDuration = Duration.fromObject({ days: 2 });

  return (
    <Container
      size="xl"
      titleText={i18n.t('travelDates.title')}
      subtitleText={i18n.t('travelDates.subtitle')}>
      <Controller
        control={control}
        name="tripDates"
        rules={{
          validate: {
            validateTripDuration: v =>
              validateTravelDatesSubmitButton({
                isModal,
                startDate: v.startDate,
                endDate: v.endDate,
                tripEndDate,
                tripStartDate,
              }),
          },
        }}
        render={({ field: { onChange } }) => {
          return (
            <Calendar
              editMode
              onSelectDuration={handleSelectDateDuration(onChange)}
              country={region?.country || 'AU'}
              numberOfProtectionRestrictionDays={protectionRestrictionDays}
              minDate={currentDate.plus({ days: minDateOffsetDays })}
              startDate={startDate}
              endDate={endDate}
              onValidateStartDate={validateTripStartDate}
              maxDate={currentDate.plus(maxTripCalendarDaysDuration).minus(twoDayDuration)}
              onValidate={validateTripDuration}
              onRestrictionDayPressed={openRestrictionDayWarningModal}
            />
          );
        }}
      />

      <div className="flex-col items-center pt-8">
        <div className="flex w-full flex-row items-center">
          <DateSelected
            className="flex-1"
            variant="departing"
            dateText={
              startDate
                ? regionDateUtils().getFullDateStr(startDate, {
                    ordinal: false,
                    fullMonth: true,
                  })
                : undefined
            }
          />
          <DaysOfTravel startDate={startDate} endDate={endDate} />
          <DateSelected
            className="flex-1"
            variant="returning"
            dateText={
              endDate
                ? regionDateUtils().getFullDateStr(endDate, {
                    ordinal: false,
                    fullMonth: true,
                  })
                : undefined
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Badge
            variant="info"
            className="my-4"
            title={i18n.t('globalShared.timezones.travelDates')}
          />
        </div>
      </div>
      <hr className="border-cabo-200 mb-6" />

      <div className={twMerge('flex flex-col gap-4', isModal && 'flex-col md:flex-row-reverse')}>
        <FooterButtonGroup
          nextButtonProps={{
            variant: 'primary',
            title: isModal
              ? i18n.t('travelDates.modal.actions.update')
              : i18n.t('global.actions.next'),
            onClick: handleSubmit(onSubmit),
            disabled: !isValid,
            isLoading,
            className: 'w-full',
            ...testProps('btn-travel-dates-next'),
          }}
          backButtonProps={{
            variant: 'secondary',
            title: isModal
              ? i18n.t('travelDates.modal.actions.cancel')
              : i18n.t('global.actions.back'),
            onClick: onBack,
            className: 'w-full',
            ...testProps('btn-travel-dates-back'),
          }}
        />
      </div>
    </Container>
  );
};
