import { Radio } from '@elements/radio';
import { testProps } from '@utils';

export type YesNoRadioButtonProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  hasAgreed?: boolean;
  name: string;
};
export const YesNoRadioButton = ({ id, hasAgreed, onChange, name }: YesNoRadioButtonProps) => {
  const hasSelectedNo = hasAgreed === false;
  const hasSelectedYes = hasAgreed === true;
  return (
    <div className="flex">
      <Radio
        title="Yes"
        checked={hasSelectedYes}
        id={`${id ?? ''}-yes`}
        onChange={onChange}
        name={name}
        {...testProps('radio-yes')}
      />
      <Radio
        name={name}
        title="No"
        checked={hasSelectedNo}
        classnames="ml-4"
        id={`${id ?? ''}-no`}
        onChange={onChange}
        {...testProps('radio-no')}
      />
    </div>
  );
};
