import { Text } from '@elements/text';

type RadioTextProps = {
  title: string;
  description?: string;
  disabled?: boolean;
};

export const RadioText = ({ title, description, disabled }: RadioTextProps) => {
  const titleVariant = description ? 'body-16/sb' : 'body-16/m';

  return (
    <div className="ml-s12 flex flex-col">
      <Text variant={titleVariant} className={disabled ? 'text-disabled' : 'text-body'}>
        {title}
      </Text>
      {description && (
        <Text variant="body-16/m" className={disabled ? 'text-disabled' : 'text-subtle'}>
          {description}
        </Text>
      )}
    </div>
  );
};
