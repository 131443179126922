import { StateCreator } from 'zustand';

import { AutoDiscountCode, RegionCode, TripDestination } from '@packages/types';

import { FeatureFlagsArgs, getFeatureFlags } from './featureFlags.api';

type PostHogFeatureFlagPayload<T> = {
  default?: T;
  mobile?: FeatureFlagsPayload<T>;
  web?: FeatureFlagsPayload<T>;
  qw?: FeatureFlagsPayload<T>;
};

type FeatureFlagsPayload<T> = {
  default?: T;
  AU?: T;
  US?: T;
};

export type MotorCycleUpsellPayload = {
  primaryTravellerAge?: {
    max: number;
    min: number;
  };
  destinations?: (TripDestination & { title: string; description: string })[];
  defaultText?: {
    title: string;
    description: string;
  };
};

/**
 * DISCOUNT CODE CONFIG PAYLOAD
 */
export type AutoDiscountCodePayload = AutoDiscountCode[];

export type DiscountCodeInputPayload = {
  pages?: {
    [key: string]: boolean;
  };
};

export type CruiseCoverNotificationPayload = {
  title: string;
  subtitle: string;
  cruiseCountryCodes: string[];
};

export type ReferralPayload = {
  hostUrl: string;
};

export type ExtraCancellationAddedByDefaultForCanstarPayload = {
  canstarCanxAmount: number;
  extraCancellationPopover: {
    heading: string;
    title: string;
    content: string;
  };
};

type Illustration = {
  iconType: 'christmas' | 'new-year';
  startTime: string;
  endTime: string;
};
type FunIllustrationsPayload = {
  iconMap: Illustration[];
};

export type FeatureFlagsPayloads = {
  'motorcycle-boost-upsell'?: MotorCycleUpsellPayload;
  'abandon-cart'?: undefined;
  'email-quote'?: undefined;
  'discount-code-input'?: DiscountCodeInputPayload;
  'auto-discount'?: AutoDiscountCodePayload;
  'email-quote-modal-cs-number'?: { csNumberEnabled: boolean };
  'cruise-cover-notification'?: CruiseCoverNotificationPayload;
  emc?: undefined;
  referral?: ReferralPayload;
  'extra-cancellation-added-by-default-for-canstar-tooltip'?: ExtraCancellationAddedByDefaultForCanstarPayload;
  'fun-illustrations'?: FunIllustrationsPayload;
};

export type FeatureFlagsVariants = {
  'motorcycle-boost-upsell'?: undefined;
  'abandon-cart'?: undefined;
  'email-quote'?: undefined;
  'discount-code-input'?: undefined;
  'auto-discount'?: undefined;
  'email-quote-modal-cs-number'?: 'with-cs-number' | 'without-cs-number';
  'cruise-cover-notification'?: undefined;
  'fun-illustrations'?: undefined;
  referral?: undefined;
  emc?: undefined;
  'extra-cancellation-added-by-default-for-canstar-tooltip'?: undefined;
};

export type FeatureFlagsState = {
  featureFlags?: {
    [key in keyof FeatureFlagsPayloads]: {
      isEnabled?: boolean;
      payload?: FeatureFlagsPayloads[key];
      value?: FeatureFlagsVariants[key];
    };
  };
  conditions?: {
    region?: RegionCode;
    platform?: 'mobile' | 'web' | 'qw';
  };
};

export type FeatureFlagsActions = {
  initFeatureFlags: (args: FeatureFlagsArgs) => Promise<FeatureFlagsState['featureFlags']>;
};

const initialState: FeatureFlagsState = {
  featureFlags: {
    'motorcycle-boost-upsell': undefined,
    'abandon-cart': undefined,
    'discount-code-input': undefined,
    'email-quote': undefined,
    'auto-discount': undefined,
    'email-quote-modal-cs-number': undefined,
    'cruise-cover-notification': undefined,
    'extra-cancellation-added-by-default-for-canstar-tooltip': undefined,
    'fun-illustrations': undefined,
    referral: undefined,
  },
};

export type FeatureFlagsStore = FeatureFlagsState & FeatureFlagsActions;

export const REMOVE_GROUP_DISCOUNT = 'removeDiscount';

export const featureFlagsStore: StateCreator<FeatureFlagsStore, [['zustand/immer', never]]> = (
  set,
  get,
) => ({
  ...initialState,
  initFeatureFlags: async args => {
    const response = await getFeatureFlags(args);
    set(state => {
      if (state.conditions) {
        state.conditions.platform = args.platform;
        state.conditions.region = args.region;
      }
      const flags = Object.keys(response?.featureFlags || {});

      flags.forEach(flag => {
        const isEnabled = !!response?.featureFlags?.[flag] as boolean;
        const featureFlagPayloadsKeys = Object.keys(response?.featureFlagPayloads || {});

        const featureFlag = {
          isEnabled: isEnabled,
          value: response?.featureFlags?.[flag],
          payload: featureFlagPayloadsKeys.includes(flag)
            ? getConditionSpecificPayload(
                state.conditions,
                JSON.parse(response?.featureFlagPayloads?.[flag] ?? ''),
              )
            : undefined,
        };
        (state.featureFlags as any)[flag] = featureFlag;
      });
    });
    return get().featureFlags;
  },
});

type ConditionArgs = FeatureFlagsState['conditions'];
type PayloadType = PostHogFeatureFlagPayload<FeatureFlagsPayloads[keyof FeatureFlagsPayloads]>;

const getConditionSpecificPayload = (
  args: ConditionArgs,
  payload?: PayloadType,
): FeatureFlagsPayloads[keyof FeatureFlagsPayloads] | undefined => {
  const defaultPayload = payload?.default;

  if (defaultPayload && Object.values(defaultPayload).length > 0) {
    return defaultPayload;
  }

  if (!args?.platform) return undefined;
  const platformPayload = payload?.[args?.platform]?.default;

  if (args?.platform && platformPayload && Object.values(platformPayload).length > 0) {
    return platformPayload;
  }

  if (!args?.region) return undefined;
  const regionPayload = payload?.[args?.platform]?.[args?.region];

  if (args?.region) {
    return regionPayload;
  }

  return undefined;
};
