import { useEffect, useState } from 'react';

import { EmcNeedHelp, Text } from '@components';
import { Button } from '@elements/button';
import { Checkbox } from '@elements/checkbox';
import { i18n } from '@i18n';
import { useBoostDetailsStore, useEmcStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { QuestionList } from './questionList.component';

export const EmcPreAssessment = () => {
  const [shouldDisplayCheckboxError, setShouldDisplayCheckboxError] = useState(false);
  const [hasAgreedPrivacyNotice, setHasAgreedPrivacyNotice] = useState(false);
  const screeningQuestions = useEmcStore(state => state.screeningQuestions);
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);

  const hasNotDeclaredNo = screeningQuestions.some(it => !it?.hasSelectedNo);
  const isButtonDisabled = !hasAgreedPrivacyNotice || hasNotDeclaredNo;

  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);

  const onButtonClick = () => {
    window.document?.getElementById('slide-modal-content')?.scrollTo(0, 0);
    return setOpenEmcSection('assessment');
  };

  useEffect(() => {
    sendAnalyticsEvent('Pre-assessment page landed', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  }, [emcDeclaring?.travellerId]);

  return (
    <div className="h-full grid gap-5">
      <QuestionList />
      <div className="flex sm:items-start md:items-center mt-4">
        <Checkbox
          name="privacy-notice-confirm"
          className="py-0"
          isChecked={hasAgreedPrivacyNotice}
          errorMessage={
            shouldDisplayCheckboxError
              ? i18n.t('emcShared.preAssessment.checkbox.error')
              : undefined
          }
          onClick={() => {
            setHasAgreedPrivacyNotice(p => !p);
            // If the user unselects the check, we show the error message
            setShouldDisplayCheckboxError(!!hasAgreedPrivacyNotice);
          }}
          labelProps={{
            children: `${i18n.t('emcShared.preAssessment.checkbox.label')}`,
            variant: 'body-16/m',
          }}
          {...testProps('checkbox-pre-assessment')}
        />
      </div>
      <Text variant="body-16/r">{i18n.t('emcShared.preAssessment.summary')}</Text>
      <div className="flex flex-col md:flex-row items-center gap-3 md:justify-end">
        <Button
          disabled={isButtonDisabled}
          className="mt-10 w-full sm:w-auto md:mb-4"
          variant="primary"
          onClick={onButtonClick}
          title={i18n.t('emcShared.preAssessment.button')}
          {...testProps('btn-continue-assessment')}
        />

        <EmcNeedHelp className="sm:hidden mt-3 mb-8" />
      </div>
    </div>
  );
};
