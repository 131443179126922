import { tv } from 'tailwind-variants';

import { getRadioVariant } from './radio.utils';

const radioIndicatorStyles = tv({
  base: 'w-6 h-6 flex items-center justify-center rounded-full border-2 transition-all bg-surface-primary border-border-bold peer-hover:border-border-action-hover',
  variants: {
    variant: {
      active: 'border-border-action-active',
      disabled: 'border-border-disable bg-surface-disabled peer-hover:border-border-disable',
    },
  },
});

const radioInnerCircleStyles = tv({
  base: 'w-4 h-4 rounded-full',
  variants: {
    variant: {
      active: 'bg-border-action-active',
      disabled: 'bg-border-disable',
    },
  },
});

type RadioIndicatorProps = {
  checked?: boolean;
  disabled?: boolean;
};

export const RadioIndicator = ({ checked, disabled }: RadioIndicatorProps) => {
  const variant = getRadioVariant(disabled, checked);

  return (
    <div className={radioIndicatorStyles({ variant })}>
      <div
        className={radioInnerCircleStyles({
          variant,
        })}
      />
    </div>
  );
};
