import { useEffect } from 'react';

import { cruiseCoverBoostIdSelector, useTripStore } from '@store';

import { useBoostSelectionModal } from './useBoostSelectionModal';

export function useCruiseBoostPopUp() {
  const setShouldShowCruiseModal = useTripStore(state => state.setShouldShowCruiseModal);
  const shouldShow = useTripStore(state => state.shouldShowCruiseModal);
  const boostId = useTripStore(cruiseCoverBoostIdSelector);
  const openBoostSelectionModal = useBoostSelectionModal(boostId);
  useEffect(() => {
    if (!shouldShow) {
      return;
    }

    openBoostSelectionModal({
      onSuccess: () => {
        setShouldShowCruiseModal(false);
      },
      showCloseButton: false,
      isCruisePopUp: true,
    });
  }, [openBoostSelectionModal, setShouldShowCruiseModal, shouldShow]);
}
