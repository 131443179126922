import { DateTime } from 'luxon';

import { regionDateUtils, useConfigStore, useRegionStore } from '@packages/stores';
import { BOOST_CATEGORY } from '@packages/types';
import {
  getAgeBand,
  getCookie,
  getDurationOfDatesInDays,
  getRegionDateTime,
} from '@packages/utils';

import { useFeatureFlagsStore, useTripStore } from '@store';

import {
  getAnonymousId,
  getClickIdAnonymousId,
  removeClickIdAnonymousId,
  sendAnalyticsECommerce,
  sendAnalyticsEvent,
  sendAnalyticsPage,
} from './analytics';

export const sendAnalyticsPaymentSuccessful = async () => {
  const trip = useTripStore.getState().trip;
  const regionSpecificConfig = useConfigStore.getState().regionSpecificConfig;
  const region = useRegionStore.getState().region;
  const tripAmount = trip.price ? trip.price / 100 : 0;
  const { clickId, anonymousId } = getClickIdAnonymousId();

  const marketingAnonymousId = anonymousId ? anonymousId : ((await getAnonymousId()) ?? '');

  sendAnalyticsEvent('Payment Successful', {
    revenue: tripAmount,
    currency: regionSpecificConfig?.CURRENCY,
    tripDuration: getDurationOfDatesInDays(trip.startDate, trip.endDate, region?.country),
    daysToTripStart: getDurationOfDatesInDays(
      getRegionDateTime(region?.country),
      trip.startDate,
      region?.country,
    ),
    destinations: trip.destinations.map(({ longName }) => longName),
    extrasSelected: trip.boosts.filter(({ isAdded }) => isAdded).map(({ name }) => name),
    numTravellers: trip.secondaryTravellers.filter(({ isSelected }) => isSelected).length + 1,
    numDependants: trip.secondaryTravellers.filter(
      ({ isSelected, isDependant }) => isSelected && isDependant,
    ).length,
    travellerAges: [
      regionDateUtils().getAge(trip.primaryTraveller?.dob as string),
      ...trip.secondaryTravellers.map(({ dob }) => regionDateUtils().getAge(dob as string)),
    ],
    promoCode: trip.promotionCode?.discountCode || '',
    trip_id: trip?.sortKey,
    amount_exgst: tripAmount - tripAmount / 11,
    marketingSiteAnonymousId: marketingAnonymousId,
    clickId: clickId,
    customerStatus: (await getAnonymousId()) === marketingAnonymousId ? 'new' : 'returning',
    endDate: DateTime.fromISO(trip.endDate).toFormat('dd MMMM yyyy'),
    startDate: DateTime.fromISO(trip.startDate).toFormat('dd MMMM yyyy'),
    vwo_uuid: getCookie('_vwo_uuid'),
  });

  removeClickIdAnonymousId();

  sendAnalyticsECommerce('Order Completed', {
    revenue: tripAmount,
    currency: regionSpecificConfig?.CURRENCY,
    product: {},
    orderId: trip.sortKey,
    fbp: getCookie('_fbp'),
    fbc: getCookie('_fbc'),
  });
};

type FeatureFlagInitResponse = Awaited<
  ReturnType<ReturnType<typeof useFeatureFlagsStore.getState>['initFeatureFlags']>
>;

export const sendFeatureFlagEvents = (featureFlagResponse: FeatureFlagInitResponse) => {
  if (featureFlagResponse) {
    for (const [key, value] of Object.entries(featureFlagResponse)) {
      sendAnalyticsEvent('Received Feature Flag', {
        flagName: key,
        variationValue: value?.value,
      });
    }
  }
};

export const sendAnalyticsTravellerDetailsPageView = () => {
  const trip = useTripStore.getState().trip;
  const addedBoosts = trip.boosts.filter(boost => boost.isAdded);
  const tripBoosts = addedBoosts
    .filter(boost => boost.category !== BOOST_CATEGORY.MY_ACTIVITIES)
    .map(boost => boost.name);
  const ppdBoosts = addedBoosts
    .filter(boost => boost.category === BOOST_CATEGORY.MY_ACTIVITIES)
    .map(boost => boost.name);

  sendAnalyticsECommerce('Product List Viewed', {
    products: addedBoosts.map(boost => boost.name),
  });

  sendAnalyticsPage('Policy Details Page Viewed');

  sendAnalyticsECommerce('Trip Summary Screen Viewed', {
    tripBoosts: [...tripBoosts],
    ppdBoosts: [...ppdBoosts],
    totalAmountDue: trip?.price ? trip?.price / 100 : 0,
  });
};

export const sendAnalyticsMyQuotePageView = () => {
  sendAnalyticsECommerce('Checkout Started');
  sendAnalyticsPage('Trip Quote Page Viewed');
  const trip = useTripStore.getState().trip;
  // computed values
  const travellerAge = regionDateUtils().getAge(trip?.primaryTraveller?.dob ?? '');
  const primaryTravellerAgeBand = travellerAge !== undefined ? getAgeBand(travellerAge) : undefined;
  const totalQuoted = trip?.price / 100;
  const totalTripDays = trip.boosts.find(
    boost => boost.category === BOOST_CATEGORY.MY_HEALTH,
  )?.duration;
  const destinations = trip?.destinations.map(destination => {
    return destination.longName;
  });

  const numTravellers = trip.secondaryTravellers.filter(({ isSelected }) => isSelected).length + 1;
  const numDependants = trip.secondaryTravellers.filter(
    ({ isSelected, isDependant }) => isSelected && isDependant,
  ).length;
  const travellerAges = [
    regionDateUtils().getAge(trip.primaryTraveller?.dob as string),
    ...trip.secondaryTravellers.map(({ dob }) => regionDateUtils().getAge(dob as string)),
  ];

  sendAnalyticsECommerce('Quick Quote Summary Screen', {
    totalQuoted,
    totalTripDays,
    destinations,
    primaryTravellerAge: travellerAge,
    primaryTravellerAgeBand,
    numTravellers,
    numDependants,
    travellerAges,
  });
};
