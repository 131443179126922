export const errorsUS = {
  outage: {
    title: 'Oops! Something went wrong...',
    description: 'We apologise for the inconvenience, please reload this page and try again.',
  },
  quoteRetry: {
    descriptionTwo:
      'Or alternatively, get in touch our customer services on [{{CUSTOMER_SERVICE_OVERSEAS_NUMBER}}](tel:18442468480) for assistance',
  },
  updated: {
    actions: {
      reload: 'Reload Page',
    },
  },
  errorBoundary: {
    errorText: {
      paymentInProgress: {
        title: 'Oops! We’re still processing your payment',
        body: 'Please do not try and re-purchase your travel insurance (as we are still processing your payment).\n\nOnce we’ve processed your payment (within 90 minutes), we will send you an email containing your policy.\n\nIf you have questions, please contact our Customer Solutions Team.',
      },
    },
  },
};
