export const boostSharedAU = {
  tagInfo: {
    heading: 'Popular badges',
    description:
      'Our popular suggestions are general in nature and do not take into account your individual circumstances.<br/><br/>These are based on volume of Freely policies sold to customers in Australia over the past calendar year.<br/><br/>Please carefully consider your own situation and read the PDS to determine if this product is right for you.',
  },
  info: 'What does popular mean?',
  payPerDayModal: {
    cruiseCoverPopUp: {
      calendarTitle: 'Which days will you be on your cruise?',
      notificationTitleMobile: `You must add our Cruise Cover Boost to be covered for cruising. You'll see this added to your policy on the next page.`,
      notificationTitle:
        "If your cruise is 2 or more nights, you must add our Cruise Cover Boost to be covered. You'll see this added to your policy on the next page.",
    },
    notification: {
      title: 'You can now add multiple dates',
      body: 'Either select a range of dates you need cover for, or double tap to select any single days',
    },
  },
};
