import { cognitoCredentialsProvider } from 'aws-amplify/auth/cognito';
import compact from 'lodash/compact';
import React, { useEffect, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  getRegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { TRIP_STATUS } from '@packages/types';
import {
  getDestinationsLabel,
  getMonthName,
  getPrimaryDestination,
  getTripTravelDates,
  wait,
} from '@packages/utils';

import { Assets } from '@assets';
import { AddToAppleWallet, Container, Loader, StarRating, Text, TripHeader } from '@components';
import { Button, buttonVariants } from '@elements/button';
import { Notification } from '@elements/notification';
import { i18n } from '@i18n';
import { withScope } from '@sentry/react';
import {
  getTripSelector,
  openModalSelector,
  useCheckoutStore,
  useFeatureFlagsStore,
  useGlobalStore,
  useGuestStore,
  useModalStore,
  useTripStore,
} from '@store';
import {
  capitalizeFirstLetter,
  clearSessionStorage,
  growsurfApi,
  isAndroid,
  isIOS,
  isIOSPhone,
  sluggify,
  testProps,
} from '@utils';

export const Confirmation: React.FC = () => {
  const customerServiceNumber = useConfigStore(
    state => state.regionSpecificConfig?.CUSTOMER_SERVICE_NUMBER,
  );
  const customerServiceNumberLink = customerServiceNumber?.replace(/\s+/g, '');

  const config = useConfigStore(state => state?.config);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const isAppleWalletEnabled = regionSpecificConfig?.FEATURE_TOGGLE?.appleWallet.enabled;
  const CONFIRMATION_IMAGE_TAG = regionSpecificConfig?.MARKETING_IMAGE_TAGS?.confirmation;
  const guest = useGuestStore(state => state.guest);
  const getTrip = useTripStore(state => state.getTrip);
  const openModal = useModalStore(openModalSelector);
  const trip = useTripStore(getTripSelector);
  const timer = useRef<number>();
  const region = useRegionStore(getRegionSelector);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const tripStatus = useTripStore(state => state.trip.state);
  const isReferralModalClosed = useGlobalStore(state => state.isReferralModalClosed);
  const referralFlag = useFeatureFlagsStore(state => state?.featureFlags?.['referral']?.isEnabled);
  const googleCreditCardDetails = useCheckoutStore(state => state.googleCreditCardDetails);
  const googlePaymentToken = googleCreditCardDetails?.paymentToken;

  const noPolicyNumber = i18n.t('checkout.confirmation.content.input.value');
  const primaryTravellerText = i18n.t('checkout.confirmation.content.traveller.description', {
    age: regionDateUtils().getAge(guest?.dob ?? ''),
  });

  const messages = i18n.t('translation:checkout.confirmation.content.processing', {
    returnObjects: true,
    email: guest?.email,
    customerServiceNumber,
    customerServiceNumberLink,
  });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      window.confirm('Going back will lose your trip confirmation data');
      clearSessionStorage();
      window.location.href = `/quote/${region?.country?.toLowerCase() ?? ''}`;
    }
  }, [blocker.state, region?.country]);

  const handleDownloadApp = () => {
    if (config?.APP_DOWNLOAD_LINK && (isIOS() || isAndroid())) {
      window.location.href = config?.APP_DOWNLOAD_LINK;
      return;
    }

    openModal('Modal', {
      body: {
        type: 'ScanToDownload',
      },
      size: 'lg',
    });
  };

  const icons = [
    {
      id: 'documentation',
      Icon: Assets.Documentation,
      label: i18n.t('checkout.confirmation.content.benefits.label'),
    },
    {
      id: 'emergency',
      Icon: Assets.Ambulance,
      label: i18n.t('checkout.confirmation.content.benefits.label2'),
    },
    {
      id: 'cs',
      Icon: Assets.CustomerSupport,
      label: i18n.t('checkout.confirmation.content.benefits.label3'),
    },
    regionSpecificConfig?.RULES.CAN_UPDATE_POLICY && {
      id: 'policy',
      Icon: Assets.Toggles,
      label: i18n.t('checkout.confirmation.content.benefits.label4'),
    },
    {
      id: 'alert',
      Icon: Assets.Alert,
      label: i18n.t('checkout.confirmation.content.benefits.label5'),
    },
    {
      id: 'claim',
      Icon: Assets.Claim,
      label: i18n.t('checkout.confirmation.content.benefits.label6'),
    },
  ];

  const handleCopyPolicyNumber = async () => {
    if (!trip.policy?.policyNumber) {
      return;
    }
    await navigator.clipboard.writeText(trip.policy?.policyNumber);
    setIsCopied(true);
  };

  const handleButtonPress = () => {
    if (config?.APPS_FLYER_DEEP_LINK) {
      window.location.href = config.APPS_FLYER_DEEP_LINK;
    }
  };

  useEffect(() => {
    withScope(async scope => {
      scope.setTag('source', 'clearCredentialsAndIdentityId');
      await getTrip();
      await cognitoCredentialsProvider.clearCredentialsAndIdentityId();
    });
  }, [getTrip]);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    timer.current = window.setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => {
      window.clearTimeout(timer.current);
    };
  }, [isCopied]);

  const isPaid = tripStatus === TRIP_STATUS.PAID;

  useEffect(() => {
    // BR: If the referral modal has been closed the user will never see the referral modal again
    const handleReferralModal = async () => {
      if (
        referralFlag &&
        isPaid &&
        !isReferralModalClosed &&
        guest?.email &&
        guest?.firstName &&
        guest?.lastName
      ) {
        await growsurfApi.init();
        await wait(1);
        openModal('Modal', {
          body: {
            type: 'ReferralModal',
          },
          shouldCloseOnOverlayClick: false,
          showCloseButton: false,
          size: 'lg',
        });
      }
    };
    handleReferralModal();
  }, [
    guest?.email,
    guest?.firstName,
    guest?.lastName,
    isPaid,
    isReferralModalClosed,
    openModal,
    referralFlag,
  ]);

  const status = isPaid ? 'active' : 'warning';
  const title = isPaid
    ? i18n.t('checkout.confirmation.banner.title', {
        destination: getPrimaryDestination(trip?.destinations),
        date: getMonthName(trip.startDate, region?.country),
      })
    : i18n.t('checkout.confirmation.banner.processing.title', {
        destination: getPrimaryDestination(trip?.destinations),
        date: getMonthName(trip.startDate, region?.country),
      });
  const tag = isPaid
    ? i18n.t('checkout.confirmation.header.tag.covered')
    : i18n.t('checkout.confirmation.header.tag.processing');

  const isPaidSubtitle = `We’ve emailed your policy information to ${guest?.email}. Check your spam folder if you can’t find it.`;

  return (
    <div className="relative">
      {CONFIRMATION_IMAGE_TAG && (
        <img
          className="absolute -top-96 -left-96"
          src={CONFIRMATION_IMAGE_TAG}
          width="1"
          height="1"
          alt=""
        />
      )}
      <TripHeader
        titleTextProps={{ children: title }}
        subtitleTextProps={{ children: isPaid && isPaidSubtitle }}
        variant="CONFIRMATION"
        tag={tag}
        statusVariant={status}
      />
      <Container className="flex flex-col gap-6">
        {isPaid ? (
          <>
            <div className="relative">
              <img
                src={Assets.FreelyIphone}
                className="absolute w-2/3 hidden -translate-y-[65%] translate-x-[55%] lg:block pl-10"
              />
            </div>
            {/* Google Pay US Confirmation */}
            {googlePaymentToken && (
              <div className="flex flex-row items-start space-x-4">
                <Assets.CheckCircleOutline height={42} width={42} />
                <div>
                  <Text>
                    <span className="font-bold">
                      Your {capitalizeFirstLetter(googleCreditCardDetails?.cardType)}****
                      {googleCreditCardDetails?.cardNumber} with Google Pay
                    </span>{' '}
                    was successfully charged.
                  </Text>
                  <Text className="pt-8">Check your email for your receipt</Text>
                </div>
              </div>
            )}

            <Text
              variant="h4-24/sb"
              className="lg:max-w-[50%]"
              {...testProps('confirmation-content-title')}>
              {i18n.t('checkout.confirmation.content.title')}
            </Text>

            <ul role="list" className="grid grid-cols-3 md:grid-cols-6">
              {compact(icons).map(({ id, Icon, label }) => (
                <li key={id} className="col-span-1 flex flex-col">
                  <div className="flex flex-1 flex-col items-center p-2 lg:p-4">
                    <Icon className="h-16 w-16" />
                    <Text
                      variant={{
                        sm: 'subHeading-14/m',
                        md: 'subHeading-14/m',
                        lg: 'body-16/m',
                      }}
                      className="mt-1 text-center"
                      {...testProps(`confirmation-icon-${sluggify(label)}`)}>
                      {label}
                    </Text>
                  </div>
                </li>
              ))}
            </ul>
            <div className="grid flex-1 grid-cols-1 items-center gap-6 md:grid-cols-2">
              <Button
                {...testProps('download-freely-button')}
                variant="primary"
                onClick={handleDownloadApp}
                title="Download Freely"
              />
              <div className="flex flex-wrap gap-4">
                <img src={Assets.FreelyAppIcon} className="h-16 w-16 rounded-lg" />
                <StarRating ratingValue={config?.APPSTORE.rating ?? 0} />
                {/* The apple wallet should only be displayed on an iphone */}
                {isAppleWalletEnabled && isIOSPhone() ? (
                  <AddToAppleWallet onClick={handleButtonPress} />
                ) : null}
              </div>
            </div>
            <div className="my-2 grid grid-cols-1 items-center gap-4 lg:grid-cols-2">
              <Text
                {...testProps(`${i18n.t('checkout.confirmation.content.header')}`)}
                variant="h4-24/sb">
                {i18n.t('checkout.confirmation.content.header')}
              </Text>
              <div className="mt-1 flex rounded-md">
                <div className="border-fuji-50 rounded-l-lg border bg-gray-50 px-2.5 py-1.5">
                  <label htmlFor="name" className="text-fuji-300 block">
                    <Text variant="footnote-12/m">
                      {i18n.t('checkout.text_postPurchase_policyNumber')}
                    </Text>
                  </label>
                  <input
                    {...testProps('policy-number-input')}
                    type="text"
                    readOnly
                    className="block w-full border-0 bg-cabo-50 p-0 text-2xl font-bold"
                    value={trip.policy?.policyNumber ?? noPolicyNumber}
                  />
                </div>
                <button
                  {...testProps('copy-policy-number-button')}
                  type="button"
                  className={twMerge(
                    'border-fuji-50 focus:border-nusa-200 focus:ring-nusa-200 relative -ml-px inline-flex w-20 items-center justify-center space-x-2 rounded-r-lg border px-4  py-2 focus:outline-none focus:ring-1',
                    !trip.policy?.policyNumber ? 'bg-fuji-50' : 'bg-cabo-200',
                  )}
                  disabled={!trip.policy?.policyNumber}
                  onClick={handleCopyPolicyNumber}>
                  {!trip.policy?.policyNumber && trip.policyStatus !== 'ERROR' ? (
                    <Loader size="sm" />
                  ) : (
                    <Text variant="body-16/sb" {...testProps('confirmation-copy-policy-number')}>
                      {isCopied
                        ? i18n.t('checkout.confirmation.content.input.button.actionDone')
                        : i18n.t('checkout.confirmation.content.input.button.primaryAction')}
                    </Text>
                  )}
                </button>
              </div>
            </div>
            <div className="mb-6 grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-6">
                <div className="space-y-2">
                  <Text variant="body-16/sb" {...testProps('confirmation-trip-title')}>
                    {i18n.t('checkout.confirmation.content.trip.title')}
                  </Text>
                  <Text variant="body-16/m" {...testProps('confirmation-destinations-label')}>
                    {getDestinationsLabel(trip)}
                  </Text>
                  <Text variant="body-16/m" {...testProps('confirmation-trip-dates')}>
                    {getTripTravelDates(trip)}
                  </Text>
                </div>
                {guest?.firstName && (
                  <div className="space-y-2">
                    <Text variant="body-16/sb" {...testProps('confirmation-traveller-title')}>
                      {i18n.t('checkout.confirmation.content.traveller.title')}
                    </Text>
                    <Text variant="body-16/m" {...testProps('confirmation-traveller-name')}>
                      {guest?.firstName} {guest?.lastName} {primaryTravellerText}
                    </Text>
                    {(guest.secondaryTravellers ?? []).map((traveller, i) => (
                      <Text
                        key={traveller.sortKey ?? i}
                        variant="body-16/m"
                        {...testProps('confirmation-traveller-details')}>
                        {traveller?.firstName} {traveller?.lastName}{' '}
                        {i18n.t('checkout.confirmation.content.traveller.description', {
                          age: regionDateUtils().getAge(traveller?.dob ?? ''),
                        })}
                      </Text>
                    ))}
                  </div>
                )}
              </div>
              <div className="space-y-6">
                {guest?.email && (
                  <div className="space-y-2">
                    <Text variant="body-16/sb" {...testProps('confirmation-policy-title')}>
                      {i18n.t('checkout.confirmation.content.policy.title')}
                    </Text>
                    {guest?.address && (
                      <Text variant="body-16/m" {...testProps('confirmation-traveller-address')}>
                        {guest?.address}
                      </Text>
                    )}
                    <Text variant="body-16/m" {...testProps('confirmation-traveller-email')}>
                      {guest?.email}
                    </Text>
                  </div>
                )}
                <div className="space-y-2">
                  <Text variant="body-16/sb" {...testProps('confirmation-coverage-title')}>
                    {i18n.t('checkout.confirmation.content.coverage.title')}
                  </Text>
                  {trip.boosts
                    .filter(({ isAdded }) => isAdded)
                    .map((boost, index) => (
                      <Text
                        key={boost.code}
                        variant="body-16/m"
                        {...testProps(`confirmation-boost-${index}`)}>
                        {i18n.t('checkout.confirmation.content.coverage.description', {
                          boostName: boost?.name,
                          count: boost?.duration,
                        })}
                      </Text>
                    ))}
                </div>
              </div>
            </div>
            <div className="bg-nusa-200 -m-6 mt-6 flex flex-col items-center gap-4 p-10 lg:-m-0 lg:grid lg:grid-cols-3">
              <Text variant="h4-24/sb" {...testProps('confirmation-banner-title')}>
                {i18n.t('checkout.confirmation.content.banner.title')}
              </Text>
              <div>
                <Text variant="body-16/m" {...testProps('confirmation-banner-description')}>
                  {i18n.t('checkout.confirmation.content.banner.description')}
                </Text>
                {guest?.email && (
                  <Text variant="body-16/sb" {...testProps('confirmation-banner-email')}>
                    {guest?.email}
                  </Text>
                )}
              </div>

              <a
                href={config?.APP_DOWNLOAD_LINK ?? ''}
                rel="noreferrer"
                target="_blank"
                className={twMerge('lg:hidden', buttonVariants({ variant: 'primary', size: 'md' }))}
                {...testProps('confirmation-link-app-qrcode')}>
                {i18n.t('checkout.confirmation.content.banner.button.title')}
              </a>
              <img
                src={Assets.FreelyAppQR}
                alt="QR code for the Freely app"
                className="hidden h-32 w-32 justify-self-end lg:block"
              />
            </div>
          </>
        ) : (
          <>
            <Notification
              variant="warning"
              header={i18n.t('checkout.confirmation.notification.warning.title')}
              body={i18n.t('checkout.confirmation.notification.warning.description')}
            />
            <div>
              <Text
                className="pb-4"
                variant="h2-36/sb"
                {...testProps('confirmation-content-label')}>
                {i18n.t('checkout.confirmation.content.label')}
              </Text>
              <ul className="pl-5 list-disc break-words">
                {messages.map((message, index) => (
                  <li key={index} className="pt-1">
                    <Text
                      withMarkDown
                      variant="subTitle-20/r"
                      {...testProps(`confirmation-message-${sluggify(message)}`)}>
                      {message}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
