import { Amplify } from 'aws-amplify';
import { ApiError } from 'aws-amplify/api';
import get from 'lodash/get';

import type { AwsMobile, AwsWeb } from '@packages/types';

import type { LibraryOptions, ResourcesConfig } from '@aws-amplify/core';
import type { APIRestConfig } from '@aws-amplify/core/dist/esm/singleton/API/types';

export const normaliseAmplifyConfiguration = (config: AwsMobile | AwsWeb) => {
  const awsRegion = config.aws_project_region ?? 'ap-southeast-2';

  const newConfig: ResourcesConfig = {
    Auth: {
      Cognito: {
        userPoolId: config.aws_user_pools_id,
        userPoolClientId: config.aws_user_pools_web_client_id,
        identityPoolId: config.aws_cognito_identity_pool_id,
        allowGuestAccess: true,
        loginWith: {
          email: true,
          oauth: {
            domain: get(
              config,
              'oauth.domain',
              'freely-social-oauth-dev.auth.ap-southeast-2.amazoncognito.com',
            ),
            scopes: get(config, 'oauth.scope', [
              'phone',
              'email',
              'openid',
              'profile',
              'aws.cognito.signin.user.admin',
            ]),
            redirectSignIn: [get(config, 'oauth.redirectSignIn', 'freely://ssoRedirect')],
            redirectSignOut: [get(config, 'oauth.redirectSignOut', 'freely://')],
            responseType: get(config, 'oauth.responseType', 'code') as 'code' | 'token',
            providers: ['Google', 'Apple'],
          },
        },
      },
    },
    API: {
      GraphQL: {
        defaultAuthMode: 'lambda',
        endpoint: config.aws_appsync_graphqlEndpoint,
        region: config.aws_appsync_region ?? awsRegion,
      },
      REST: config.API.endpoints.reduce(
        (prev, { name: apiName, endpoint }) => {
          prev[apiName] = {
            endpoint,
            region: awsRegion,
          };
          return prev;
        },
        {} as Record<string, APIRestConfig>,
      ),
    },
    Notifications: {
      PushNotification: {
        Pinpoint: {
          appId: get(config, 'PushNotification.appId', ''),
          region: awsRegion,
        },
      },
    },
    Analytics: {
      Pinpoint: {
        appId: get(config, 'Analytics.AWSPinpoint.appId', ''),
        region: get(config, 'Analytics.AWSPinpoint.region', awsRegion),
      },
    },
    Storage: {
      S3: {
        bucket: get(config, 'Storage.AWSS3.bucket', ''),
        region: get(config, 'Storage.AWSS3.region', awsRegion),
      },
    },
  };

  return newConfig;
};

/**
 * https://docs.amplify.aws/react/build-a-backend/add-aws-services/rest-api/set-up-rest-api/#initialize-amplify-api
 */
export const amplifyConfigure = (config: AwsMobile | AwsWeb, options?: LibraryOptions) => {
  const outputs = normaliseAmplifyConfiguration(config);
  Amplify.configure(outputs, options);
};

export const throwError = ({
  error,
  message = 'API failed',
}: {
  error: unknown;
  message?: string;
}) => {
  if (
    error instanceof ApiError &&
    'response' in error &&
    typeof error.response === 'object' &&
    error.response !== null &&
    'statusCode' in error.response &&
    'body' in error.response
  ) {
    throw new Error(message);
  }
  throw new Error(message);
};

export function extractErrorMessage(input?: string, defaultErrorMessage = 'Api failed') {
  if (!input) {
    return defaultErrorMessage;
  }

  // Define the regular expression to capture the message
  const regex = /"message":"(.*?)"/;
  // Use the regex to find the match in the input string
  const match = input.match(regex);

  return match?.[1] ?? defaultErrorMessage;
}
