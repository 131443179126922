import { InputHTMLAttributes, LegacyRef, forwardRef, useState } from 'react';

import { Text, TextProps } from '@elements/text';
import { testProps } from '@utils';

import { CheckboxSVGIcon, CheckboxSVGIconProps } from './checkboxSVGIcon.component';

type CheckboxProps = {
  labelProps?: TextProps;
  errorMessage?: string;
  onClick?: () => void;
} & CheckboxSVGIconProps &
  InputHTMLAttributes<HTMLInputElement>;
export const Checkbox = forwardRef(
  (
    {
      name,
      labelProps,
      isChecked,
      disabled,
      errorMessage,
      onClick,
      className,
      ...rest
    }: CheckboxProps,
    ref?: LegacyRef<HTMLInputElement>,
  ) => {
    const hasError = !!errorMessage;
    const [isSelected, setIsSelected] = useState(isChecked);

    const handleClick = () => {
      setIsSelected(prevSelected => !prevSelected);
      onClick?.();
    };
    return (
      <div className={className}>
        <input ref={ref} type="checkbox" id={name} className="hidden" {...rest} />
        <label
          htmlFor={name}
          className="flex cursor-pointer flex-row text-left items-center -ml-s12"
          onClick={handleClick}
          defaultChecked={isSelected}>
          <span
            {...testProps('checkbox')}
            className="hover:bg-surface-action-tertiary-hover hover:rounded-full p-s12">
            <CheckboxSVGIcon isChecked={isChecked} isDisabled={disabled} hasError={hasError} />
          </span>
          {labelProps && (
            <Text variant="body-16/r" {...labelProps}>
              {labelProps.children}
            </Text>
          )}
        </label>
        {hasError && (
          <Text variant="body-16/r" className="text-error">
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
