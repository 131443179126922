import { type VariantProps, tv } from 'tailwind-variants';

export const textTypography = tv({
  variants: {
    variant: {
      'h1-44': 'text-[2.75rem]/[3.25rem]',
      'h2-36': 'text-[2.25rem]/[2.75rem]',
      'h3-28': 'text-[1.75rem]/[2.25rem]',
      'h4-24': 'text-[1.5rem]/[2rem]',
      'subTitle-20': 'text-[1.25rem]/[1.75rem]',
      'body-16': 'text-[1rem]/[1.5rem]',
      'subHeading-14': 'text-[0.875rem]/[1.25rem]',
      'footnote-12': 'text-[0.75rem]/[1rem]',
    },
    weight: {
      r: 'font-normal',
      m: 'font-medium',
      sb: 'font-semibold',
      freely: 'font-normal font-[freely]',
    },
  },
  defaultVariants: {
    variant: 'body-16',
    weight: 'r',
  },
});

type Strict<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined>;
};

// Type for the combined variant and weight
// Strict is used to remove undefined from the type
export type FontVariant =
  `${Strict<FontVariantProps>['variant']}/${Strict<FontVariantProps>['weight']}`;

export type FontVariantProps = VariantProps<typeof textTypography>;

export interface ResponsiveFontVariant {
  sm: FontVariant;
  md?: FontVariant;
  lg: FontVariant;
}

// Function to determine the HTML element name based on variant
export const getElementName = (variant?: FontVariantProps['variant']) => {
  if (variant?.startsWith('h')) {
    return variant.substring(0, 2) as keyof JSX.IntrinsicElements;
  } else {
    return 'p';
  }
};
