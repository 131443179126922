import React, { InputHTMLAttributes, LegacyRef, forwardRef } from 'react';
import { tv } from 'tailwind-variants';

import { getRadioVariant } from './radio.utils';
import { RadioIndicator } from './radioIndicator.component';
import { RadioText } from './radioText.component';

const radioContainerStyles = tv({
  base: 'inline-flex items-center p-s16 rounded-br-md border-2 cursor-pointer transition-colors border-transparent bg-surface-subtle hover:border-border-action-active hover:bg-surface-action-selected',
  variants: {
    variant: {
      active: 'border-border-action-active bg-surface-action-selected',
      disabled:
        'cursor-not-allowed bg-surface-disabled hover:bg-surface-disabled hover:border-transparent',
    },
  },
});

type RadioProps = {
  title: string;
  description?: string;
  id: string;
  checked?: boolean;
  classnames?: string;
  disabled?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef(
  (
    { title, description, id, checked, disabled, classnames, name, onChange, ...rest }: RadioProps,
    ref?: LegacyRef<HTMLInputElement>,
  ) => {
    const variant = getRadioVariant(disabled, checked);
    return (
      <div className={classnames}>
        <label htmlFor={id} className={radioContainerStyles({ variant })}>
          <input
            ref={ref}
            type="radio"
            id={id}
            name={name}
            value={title}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            className="hidden peer"
            {...rest}
          />
          <RadioIndicator checked={checked} disabled={disabled} />
          <RadioText title={title} description={description} disabled={disabled} />
        </label>
      </div>
    );
  },
);

Radio.displayName = 'Radio';
