import { FC, useMemo } from 'react';

import { colors } from '@packages/design';

export interface CheckboxSVGIconProps {
  isChecked?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
}

export const CheckboxSVGIcon: FC<CheckboxSVGIconProps> = ({ isChecked, hasError, isDisabled }) => {
  const styles = useMemo(
    () => getCheckboxStyle({ isChecked, isDisabled, hasError }),
    [isChecked, isDisabled, hasError],
  );
  return (
    <svg viewBox="0 0 25 26" width="24px" height="24px" preserveAspectRatio="none">
      <rect
        x="0.5"
        y="1"
        width="24"
        height="24"
        rx="5"
        fill={styles.fillColor}
        stroke={styles.fillStrokeColor}
      />
      {isChecked ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.39945 16.194C5.1882 14.9587 7.00507 13.1058 8.21631 14.3411L10.7902 16.966L18.8147 8.78237C20.0259 7.5471 21.7671 9.4 20.6315 10.6353L11.6986 19.6681C11.2444 20.2086 10.4117 20.2858 9.88177 19.7454L6.39945 16.194Z"
          fill={styles.checkColor}
          transform="translate(-1.1, -1.5)"
        />
      ) : null}
    </svg>
  );
};

const getCheckboxStyle = ({
  isChecked,
  isDisabled,
  hasError,
}: {
  isChecked?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
}) => {
  if (isDisabled) {
    return {
      fillColor: colors.surface.disabled,
      fillStrokeColor: colors.border.disable,
      checkColor: colors.icon.disabled,
    };
  }

  if (hasError) {
    return {
      fillColor: isChecked ? colors.surface.error : colors.surface.primary,
      fillStrokeColor: colors.border.error,
      checkColor: colors.icon.error,
    };
  }

  if (isChecked) {
    return {
      fillColor: colors.surface.action.primary.active,
      fillStrokeColor: colors.surface.action.primary.active,
      checkColor: colors.icon.primary,
    };
  }

  return {
    fillColor: colors.surface.primary,
    fillStrokeColor: colors.border.bold,
    checkColor: colors.surface.primary,
  };
};
