export const errorsAU = {
  outage: {
    title: 'Oops! Something went wrong...',
    description: 'We apologise for the inconvenience, please reload this page and try again.',
  },
  quoteRetry: {
    title: 'Oops! Something went wrong getting your quote',
    description:
      'Unfortunately, we couldn’t process your request in time. It appears we are having a temporary technical issue getting your quote, please try again.',
    descriptionTwo:
      'Or alternatively, call our customer services on [{{CUSTOMER_SERVICE_OVERSEAS_NUMBER}}](tel:+61289075085) for assistance.',
  },
  updated: {
    actions: {
      reload: 'Reload Page',
    },
  },
  errorBoundary: {
    buttonActions: {
      getANewQuote: 'Get a new Quote',
      backToHomePage: 'Back to Homepage',
    },
    phoneNumber: {
      maintenanceMode: '1-844-246-8480',
      quoteNotFound: '1300 722 183',
    },
    errorText: {
      internalError: {
        title: 'Oops!  Something went wrong...',
        body: "We're sorry to inform you that an unexpected error has occurred while processing your request.\n\nPlease close this window and try again.",
      },
      paymentInProgress: {
        title: 'Oops! We’re still processing your payment',
        body: 'Please do not try and re-purchase your travel insurance (as we are still processing your payment).\n\nOnce we’ve processed your payment (within 90 minutes), we will send you an email containing your policy.\n\nIf you have questions, please contact our customer support team.',
      },
      quoteAlreadyPaid: {
        title: 'You’ve already purchased this policy, and you’re good to go!',
        body: "The quote number {{QUOTE_ID}} you requested earlier has already been purchased. To handle or review this policy, simply log in to Freely's app.\n\nIf you're planning another trip, getting a fresh quote is a breeze – quick and hassle-free.",
      },
      quoteEligibilityChanged: {
        title: 'It looks like your eligibility has changed. ',
        body: "The quote you're looking for is for a departure date that's less than 10 days away. Because of this, there's been a change in \nyour eligibility.",
      },
      quoteExpired: {
        title: 'Oops!  This quote has expired...',
        body: "Unfortunately, the quote number {{QUOTE_ID}} you requested earlier is no longer valid.\n\nBut don't worry! Generating a new quote is quick and easy.",
      },
      quoteInvalidStatus: {
        title: 'Oops!  This quote has expired...',
        body: "Unfortunately, the quote number {{QUOTE_ID}} you requested earlier is no longer valid.\n\nBut don't worry! Generating a new quote is quick and easy.",
      },
      quoteNotFound: {
        title: 'Quote not found',
        body: "We apologise, but it appears the quote you're seeking isn't in our records.\n\nIf you believe there's been an error, feel free to call us for any inquiries or help, our team is here to assist you.",
      },
      quoteStartDatePassed: {
        title: 'It looks like the dates you entered have already passed.',
        body: 'Your travel time machine might be impressive, but unfortunately, we can only provide coverage for trips in the future.',
      },
      quoteEndDatePassed: {
        title: 'It looks like the dates you entered have already passed.',
        body: 'Your travel time machine might be impressive, but unfortunately, we can only provide coverage for trips in the future.',
      },
    },
  },
};
